import React from 'react'
import { CSheading, CSsubHeading } from '@styles/common'
import PinDropIcon from '@mui/icons-material/PinDrop';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { SContainer, SInner } from '@styles/home/TrackDivStyle';
function TrackDiv() {
    return (
        <SContainer>
            <SInner>
                <PinDropIcon style={{ fontSize: '50px' }} />
                <CSheading>TRACK YOUR CAR</CSheading>
                <CSsubHeading>Track your car, find out where your car is with a live update on its arrival time. Keeping you in the loop.</CSsubHeading>
            </SInner>
            <SInner>
                <AccessTimeIcon style={{ fontSize: '50px' }} />
                <CSheading>Priority Booking</CSheading>
                <CSsubHeading>Book online right away or schedule for later and automatically get your car quicker.</CSsubHeading>
            </SInner>
            <SInner>
                <CreditCardIcon style={{ fontSize: '50px' }} />
                <CSheading>Pay by Card</CSheading>
                <CSsubHeading>Pay by card, cash or business account. Apple or Google pay now added for secure contactless payments.</CSsubHeading>
            </SInner>

        </SContainer>
    )
}

export default TrackDiv