import tw, { styled } from 'twin.macro'
export { SContainer, SInner }

const SContainer = styled.div`
${tw`text-xl  gap-10 flex justify-center flex-col md:flex-row items-center  md:text-5xl  rounded-lg mt-2 px-2 py-5 drop-shadow-2xl`}
    img{
        ${tw`w-20`}
    }
    

`
const SInner = styled.div`
${tw`text-lg  max-w-md  md:text-5xl  `}
    
`


