import { CSImageDesc, CSheading } from '@styles/common'

import { SContainer, SInner } from '@styles/home/AirportTransferStyle'
import React from 'react'

function AirportTransferDiv() {
    return (
        <SContainer>
            <SInner>
                <img src='/assets/images/CabImage.webp' style={{ width: '80%', minWidth: '300px' }} />
                <CSheading className='text-center'>AIRPORT TRANSFERS</CSheading>
                <CSImageDesc>We pride ourselves on offering reliable and cost-effective Airport Transfers from Hyndburn to all major airports across the North West. </CSImageDesc>
            </SInner>
            <SInner>
                <img src='/assets/images/CabImage.webp' style={{ width: '80%', minWidth: '40%' }} />
                <CSheading className='text-center'>FAST & RELIABLE Service</CSheading>
                <CSImageDesc> Largest established taxi companies in the northwest.  We take more than 50,000 bookings per week, as one of the North West’s most trusted and established taxi firms, so whenever you want to get on the road, our vehicles are ready and waiting.</CSImageDesc>
            </SInner>
        </SContainer>
    )
}

export default AirportTransferDiv