import tw, { styled } from 'twin.macro'
export { SContainer, SInner, SHomeImage, SOverlay }

const SContainer = styled.div`
${tw`lg:bg-primary  flex justify-center lg:justify-between items-center `}
    /* img{
        ${tw`w-20`}
    } */
    

`
const SInner = styled.div`
${tw`text-lg  max-w-md  md:text-5xl   text-center`}
    img{
        ${tw`w-20`}
    }
`

const SHomeImage = styled.div`
${tw`bg-homeImage relative bg-cover h-[750px] w-[65%] bg-no-repeat rounded-[0% 98% 40% 10% / 10% 52% 48% 0%] text-white font-bold text-4xl 
hidden lg:block`}
h6{
    ${tw`text-warning text-5xl`}
}
`

const SOverlay = styled.div`
${tw`bg-black/30 absolute top-0 h-full w-full bg-no-repeat rounded-[0% 98% 40% 10% / 10% 52% 48% 0%] `}`