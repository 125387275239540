import Button from '@mui/material/Button';
import { CSh1, CSh4 } from '@styles/common'
import { SContainer } from '@styles/home/DownloadDivStyle'
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import React from 'react'

const SocialButton = ({ title, Icon }) => <Button variant='contained' className="bg-black hover:bg-black space-x-4">
    <Icon sx={{ fontSize: 30 }} />
    <div className='flex flex-col -space-y-1'>
        <small className="lowercase">Available at</small>
        <div>{title}</div>

    </div>
</Button>

function DownloadDiv() {
    return (
        <SContainer>
            <div>
                <CSh1 className='text-center lg:text-left'>Download This App</CSh1>
                <CSh4 className='mt-4 mr-7'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, </CSh4>
                <div className='flex items-center mt-10 gap-5 flex-col lg:flex-row'>
                    <div className='flex flex-col gap-10'>
                        <img src='/assets/images/android.png' />
                        <img src='/assets/images/appleStore.png' />

                    </div>
                    <img src='/assets/images/qr1.webp' width='40%' />
                </div>

            </div>
            <img className='hidden lg:block' src='/assets/images/phone.png' width='40%' />
        </SContainer>
    )
}

export default DownloadDiv