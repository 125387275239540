import tw, { styled } from 'twin.macro'
export { SContainer, SInner }

const SContainer = styled.div`
${tw`flex flex-col lg:grid lg:grid-cols-2 lg:gap-8 mt-2 mx-2 `}
    

`
const SInner = styled.div`
${tw`w-full flex flex-col justify-center items-center`}
   img{
         ${tw`h-[500px] w-full  `}
   }
`
