import { CSheading, CSsubHeading } from '@styles/common'

import PinDropIcon from '@mui/icons-material/PinDrop';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import React from 'react'
import { SContainer, SInner } from '@styles/home/ServicesDivStyle';
import OfferCard from '@common/OfferCard';

const data = [
    ['AirPort Transfer', '/assets/images/Airport-transfer-icon.webp'],
    ['Best Services', '/assets/images/Best-services.webp'],
    ['Fast and Easy', '/assets/images/Fast-and-easy-travel.webp'],
]
function ServicesDiv() {
    return (
        <div className='text-center mt-10'>
            <CSheading >What We Offer</CSheading>
            <SContainer>

                {data.map(([title, image], index) =>

                    <SInner key={index}>
                        <OfferCard title={title} image={image} />
                    </SInner>
                )
                }
            </SContainer>
        </div>
    )
}

export default ServicesDiv;