
require("./../node_modules/@module-federation/nextjs-mf/src/include-defaults.js");
import Head from 'next/head'
import Home from '@pages/home/Home'

export default function Index() {
  return (
    <div >
      <Head>
        <title>DEMO 4 U | Demo Taxi Website</title>
        <meta name="demo4u.co.uk" content="demo4u.co.uk" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <Home />

    </div>
  )
}
