import tw, { styled } from 'twin.macro'
export { SContainer, SInner }

const SContainer = styled.div`
${tw`text-xl gap-10 flex justify-center flex-col md:flex-row items-center  md:text-5xl px-4 rounded-lg mt-2 lg:px-10 py-5 drop-shadow-2xl`}
    img{
        ${tw`w-20`}
    }
    

`
const SInner = styled.div`
${tw`text-lg  max-w-lg  md:text-5xl bg-primary/40 md:h-[400px] lg:h-52  rounded-lg px-20 h-64 flex flex-col justify-center items-center  text-center`}
    img{
        ${tw`w-20`}
    }
`


