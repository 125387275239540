import React from 'react'
import { SContainer } from '../../styles/home/top'
import AirportTransferDiv from './AirportTransferDiv'
import BookerDiv from './BookerDiv'
import DownloadDiv from './DownloadDiv'
import ServicesDiv from './ServicesDiv'
import TrackDiv from './TrackDiv'
const Home = () => {
    let bookerUrl = process.env.NEXT_PUBLIC_BOOKERURL
    console.log('booker', bookerUrl)
    return (


        <SContainer>
            <BookerDiv />
            <ServicesDiv />
            <AirportTransferDiv />
            <TrackDiv />
            <DownloadDiv />
        </SContainer>

    )
}

export default Home