import React from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CSheading } from '@styles/common';
function OfferCard({ title, image }) {
    return (
        <Card>
            <CardContent className='flex flex-col justify-center items-center px-20 py-10 text-center'>
                <img src={image} style={{ width: '50%', marginBottom: '10%' }} />
                <CSheading>{title}</CSheading>
            </CardContent>

        </Card>
    )
}

export default OfferCard