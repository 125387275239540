import React from 'react'
import { SContainer, SHomeImage, SOverlay } from '@styles/home/BookerDivStyle'
import dynamic from 'next/dynamic';
const Booker = dynamic(() => import('main/bookerWrapper'), { ssr: false });
function BookerDiv() {

    return (
        <SContainer>
            <SHomeImage>
                <div className='flex flex-col justify-center h-full px-20 z-10 relative'>
                    <h6>The easiest and safe way to travel <br /></h6>
                    <h1>Serving north east around the clock</h1>
                </div>
                <SOverlay />
            </SHomeImage>

            {/* <iframe allowtransparency="true" frameborder="0" src={bookerUrl} className='w-[100%]  lg:w-[30%]' height={'700px'} style={{ border: '0', borderRadius: '25px' }} webkitallowfullscreen mozallowfullscreen allowfullscreen loading="lazy" allow='payment' referrerPolicy="no-referrer-when-downgrade"></iframe> */}
            <div className="lg:w-[60%] h-[800px] w-[100%] z-10">
                <Booker
                    secretId={process.env.NEXT_PUBLIC_UID}
                    qrId={process.env.NEXT_PUBLIC_QR_ID}
                    iframe
                />
            </div>
        </SContainer >
    )
}

export default BookerDiv